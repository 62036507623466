import React, { useEffect } from "react";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import home_image from "./Assets/2148666181.jpg";
import Grades from "./Components/Grades";
import about_image from "./Assets/contact.jpg";
import Courses from "./Components/Courses";
import Paper from "./Components/Paper";
import HomeCarousel from "./Components/HomeCarousel";
import privacy_image from "./Assets/privacy.jpg";
import terms_image from "./Assets/terms.jpg";
import contact_image from "./Assets/contact.jpg";
import { Helmet } from "react-helmet-async";

function Home() {
  const home_images = [
    home_image,
    about_image,
    privacy_image,
    terms_image,
    contact_image,
  ];

  // useEffect(() => {
  //   // Set the document title
  //   document.title = "Home - ICTSuhashi | Quality ICT Education";


  //   // Set the canonical URL
  //   const canonicalLink = document.createElement('link');
  //   canonicalLink.rel = "canonical";
  //   canonicalLink.href = window.location.href; // Use the current URL or your preferred canonical URL
  //   document.head.appendChild(canonicalLink);

  //   // Clean up the meta description and canonical link when the component unmounts
  //   return () => {
  //     document.head.removeChild(canonicalLink);
  //   };
  // }, []);

  return (
    <>
    <Helmet>
      <title>Home - ICTSuhashi | Quality ICT Education</title>
      <meta name="description" content="Welcome to ICTSuhashi! Explore quality ICT lessons, courses, and resources to enhance your skills in information and communication technology."/>
      <link rel="canonical" href={window.location.href}/>
      {/* <meta name="robots" content="noindex"/> */}
    </Helmet>
      <div className="w-full min-h-screen">
        <Navbar />
        <HomeCarousel home_images={home_images} />

        <Grades />
        <Courses />
      </div>
      <Paper />
      <Footer />
    </>
  );
}

export default Home;
