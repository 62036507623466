import React, { useEffect } from "react";
import privacy_image from "./Assets/privacy.jpg";

import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
const PrivacyPolicy = () => {

   useEffect(()=>{
      document.title = "Privacy Policy - ICTSuhashi";

      // Set the canonical URL
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = window.location.href; // Use the current URL or your preferred canonical URL
    document.head.appendChild(canonicalLink);

    // Clean up the meta description and canonical link when the component unmounts
    return () => {
      document.head.removeChild(canonicalLink);
    };
    },[])
  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Navbar />
        {/* <div className='relative flex items-center justify-center w-full h-auto overflow-hidden bg-black md:h-52'>
            <img className='w-full opacity-40 cover-fill' alt='' src={privacy_image}/>

            <div className='absolute'>
                <h1 className='text-4xl font-bold text-white'>Privacy Policy</h1>
            </div>
        </div> */}
        <div
          className="relative w-full overflow-hidden h-52 md:h-96"
          style={{
            backgroundImage: `url(${privacy_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundColor: "black",
          }}
        >
          {/* Black overlay */}
          <div className="absolute inset-0 bg-black opacity-40"></div>

          {/* Content */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div>
              <h1 className="text-4xl font-bold text-white">Privacy Policy</h1>
            </div>
          </div>
        </div>

        <div className="flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
          <div className="flex justify-center w-full py-5">
            <h1 className="text-2xl">Privacy Policy</h1>
          </div>
          <div className="container px-4 mx-auto mb-5">
            <p className="pb-1 mx-auto text-gray-800">
              Thank you for joining the ICTSuhashi online educational platform.
              The ICTSuhashi team values the privacy of our users and wants to
              ensure that you understand how we collect, use, and share
              information about you. Our privacy policy outlines the methods of
              information collection and also details your rights regarding
              access to our platform, as well as the ability to correct or
              restrict our use of your personal information.
            </p>
            <p className="pb-1 mx-auto text-gray-800 ">
              We collect certain information directly from you, such as the data
              you provide when signing up, details about your participation in
              courses, and information from third-party platforms you use to
              connect with ICTSuhashi. Additionally, we gather some information
              automatically, including data about your device and your
              interactions with different parts of our platform.
            </p>
            <p className="pb-1 mx-auto text-gray-800 ">
              We may gather various information from or about you relying upon
              how you utilize the Services. At the point when you setup an
              account and utilize the Services, including through a third party
              platform, we gather any information you give directly.
            </p>
            <p className="pb-1 mx-auto text-gray-800 ">
              We use cookies for things like analysing your utilization of the
              Services, customizing your experience, making it simpler to sign
              into the Services, and remembering you when you return. We use web
              beacons for things like recognizing whether a page was visited,
              distinguishing whether an email was opened, and advertising more
              effectively and efficiently by blocking current users from certain
              promotional messages or recognizing the source of a new mobile
              application download.
            </p>
            <p className="pb-1 mx-auto text-gray-800 ">
              We share certain information about you with educators, other
              students, organizations performing for us, our colleagues,
              business partners, analytics, your social media suppliers,
              organizations helping us run promotions and overviews, and
              publicizing organizations who assist us with promoting our
              Services. We may likewise share your information needed for legal
              compliance, security, or as a major aspect of a corporate
              restructuring. Finally, we can share information in different ways
              on the off chance that it is accumulated or de-recognized or in
              the event that we get your consent.
            </p>
            <p className="pb-1 mx-auto text-gray-800 ">
              We utilize appropriate security depending on the type and
              sensitivity of information being stored. Likewise with any online
              service, there is constantly a danger of unauthorized access, so
              it is a must to secure your secret key/password and to reach us in
              the event that you suspect any unauthorized access to your
              account.
            </p>
            <p className="pb-1 mx-auto text-gray-800 ">
              You have certain rights around the utilization of your
              information, including the capacity to quit promotional messages,
              cookies, and collection of your information by certain analytics
              providers. You can update or end your account within our Services,
              and can likewise reach us for individual rights requests your
              personal information. Guardians who believe we have unexpectedly
              gathered individual information about their underage youngster
              should get in touch with us for help erasing that data.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
