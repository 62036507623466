import React, { useEffect } from "react";
import contact_image from "./Assets/contact.jpg";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
const ContactUs = () => {
   useEffect(()=>{
      document.title = "Contact - ICTSuhashi";

      // Set the canonical URL
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = window.location.href; // Use the current URL or your preferred canonical URL
    document.head.appendChild(canonicalLink);

    // Clean up the meta description and canonical link when the component unmounts
    return () => {
      document.head.removeChild(canonicalLink);
    };
    },[])

  return (
    <div className="flex flex-col justify-between h-screen">
      <div>
        <Navbar />
        <div
          className="relative w-full overflow-hidden h-52 md:h-96"
          style={{
            backgroundImage: `url(${contact_image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          {/* Black overlay */}
          <div className="absolute inset-0 bg-black opacity-40"></div>

          {/* Content */}
          <div className="absolute inset-0 flex items-center justify-center">
            <div>
              <h1 className="text-4xl font-bold text-white">Contact Us</h1>
            </div>
          </div>
        </div>

        {/* Description with mobile numbers and icons */}
        <div className="flex items-center justify-center h-auto px-4 py-5 mx-auto mt-4 md:py-0">
          <div className="container text-center">
            <p className="mb-4 text-xl text-gray-700">
              Welcome to our Learning Management System (LMS). We are dedicated
              to providing the best learning experience for our users. For any
              inquiries, feel free to contact us at the numbers below:
            </p>
            <div className="flex flex-col items-center justify-center gap-5 md:flex-row">
              {/* Phone Number */}
              <div className="flex items-center cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2 text-blue-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <a href="tel:+947732456636" className="text-gray-700 underline">
                  +94 77 32456636
                </a>
              </div>

              {/* WhatsApp */}
              <div className="flex items-center cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2 text-green-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 2C6.48 2 2 6.48 2 12c0 1.96.62 3.78 1.68 5.28L2 22l4.72-1.68C8.22 21.38 10.04 22 12 22c5.52 0 10-4.48 10-10S17.52 2 12 2zm0 18c-1.66 0-3.19-.5-4.48-1.36l-.34-.2-3.48.99.99-3.48-.2-.34C4.5 15.19 4 13.66 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8zm4.9-5.1c-.25-.12-1.47-.72-1.7-.8-.23-.08-.4-.12-.57.12-.17.25-.66.8-.81.97-.15.17-.3.19-.55.07-.25-.12-1.05-.39-2-1.23-.74-.66-1.24-1.47-1.39-1.72-.15-.25-.02-.38.11-.51.11-.11.25-.29.37-.44.12-.15.16-.25.25-.41.08-.17.04-.31-.02-.43-.06-.12-.57-1.37-.78-1.88-.21-.51-.42-.44-.57-.45-.15-.01-.32-.01-.49-.01-.17 0-.45.06-.69.31-.24.25-.93.91-.93 2.22 0 1.31.95 2.58 1.08 2.75.13.18 1.88 2.86 4.59 4.01.63.27 1.13.43 1.52.55.63.2 1.2.17 1.65.1.45-.07 1.47-.6 1.68-1.18.21-.58.21-1.08.14-1.18-.07-.1-.25-.15-.5-.27z"
                  />
                </svg>
                <a
                  href="https://wa.me/07732456636"
                  className="text-gray-700 underline"
                >
                  +94 77 32456636
                </a>
              </div>

              {/* Email */}
              <div className="flex items-center cursor-pointer">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <a
                  href="mailto:ictsuhashi@gmail.com"
                  className="text-gray-700 underline"
                >
                  ictsuhashi@gmail.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
