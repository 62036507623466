import React, { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
import { db, storage } from "../../Firebase/firebase-config";
import { UserAuth } from "../../Context/AuthContext";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import axios from "axios";
import Footer from "../Home/Components/Footer";
import { provider } from "./../../Firebase/firebase-config";

const Payments = () => {
  const tabName = "/ Payments";

  useEffect(() => {
    document.title = "Payments - ICTSuhashi";
  }, []);

  // const currentDate = new Date().toLocaleDateString();
  // const currentTime = new Date().toLocaleTimeString();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };

  // Function to get formatted date (YYYY/MM/DD)
function getFormattedDate() {
  const currentDate = new Date();
  
  // Adjust to get year, month, and day in the correct format
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // months are 0-based, so add 1
  const day = String(currentDate.getDate()).padStart(2, "0");
  
  return `${year}/${month}/${day}`;
}

// Function to get formatted time (hh:mm:ss, 24-hour format)
function getFormattedTime() {
  const currentDate = new Date();
  
  // Get hours, minutes, and seconds, formatted as needed
  const hours = String(currentDate.getHours()).padStart(2, "0"); // 24-hour format, ensure 2 digits
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");

  return `${hours}:${minutes}:${seconds}`;
}


  const { user } = UserAuth();

  const { lessonId, driveFolderId, price, description, title } = useParams();

  const formattedPrice = parseFloat(price).toLocaleString("en-IN", {
    style: "currency",
    currency: "LKR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  const authEmail = user && user?.email;

  useEffect(() => {
    setPaymentInfo({ email: user && user?.email });
  }, [user]);

  const [paymentInfo, setPaymentInfo] = useState({
    email: authEmail,
  });

  const generateRandomOrderId = () => {
    const uppercaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    let orderId = "";

    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * uppercaseLetters.length);
      orderId += uppercaseLetters.charAt(randomIndex);
    }

    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * numbers.length);
      orderId += numbers.charAt(randomIndex);
    }

    return orderId;
  };

  // State to store the order ID
  const [orderId, setOrderId] = useState(generateRandomOrderId);

  const sendEmail = async (email, price, type, description) => {
    const requestBody = {
      email: email,
      price: price,
      description: description,
      type: type,
    };

    axios
      .post(
        "https://us-central1-ict-suhashi-test.cloudfunctions.net/api/email/send",
        requestBody
      )
      .then((response) => {
        // Handle success
        console.log("Email sent successfully:", response.data);
      })
      .catch((error) => {
        // Handle error
        console.error("Error sending email:", error);
      });
  };

  const handleUpload = async (e) => {
    setFile(e.target.files[0]);
  };

  const uploadSlip = async (e) => {
    setIsUploading(true);
    const user_ID = user && user?.uid;
    if (!file) {
      setIsUploading(false);
      return;
    }

    try {
      const storageRef = ref(storage, `Payment-Slips/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const paymentQuerySnapshot = await getDocs(
        query(
          collection(db, "payments"),
          where("userId", "==", user_ID),
          where("chapterId", "==", lessonId)
        )
      );

      if (!paymentQuerySnapshot.empty) {
        setIsUploading(false);
        swal({
          title: "Error",
          text: "Payment Slip Already Uploaded",
          icon: "error",
          buttons: false, // Remove the OK button
          timer: 3000, // Set the timer for 3 seconds (3000 milliseconds)
        });
        return;
      }

      // await addDoc(collection(db, "payments"), {
      //   userId: user_ID,
      //   chapterId: lessonId && lessonId,
      //   email: paymentInfo.email,
      //   slipUrl: downloadURL,
      //   payment: price,
      //   driveId: driveFolderId,
      //   isAccept: false,
      //   time: currentTime,
      //   date: currentDate,
      //   id: orderId,
      //   description: description,
      //   title: title,
      // });

      await addDoc(collection(db, "payments"), {
        userId: user_ID,
        chapterId: lessonId && lessonId,
        email: paymentInfo.email,
        slipUrl: downloadURL,
        payment: price,
        driveId: driveFolderId,
        isAccept: false,
        time: getFormattedTime(),
        date: getFormattedDate(),
        id: orderId,
        description: description,
        title: title,
      });
      sendEmail(paymentInfo.email, price, "Video", description);
      setIsUploading(false);
      swal({
        title: "Success",
        text: "Payment Slip Uploaded Successfully!\nYou Will Get the Access Soon..!",
        icon: "success",
        buttons: false, // Remove the OK button
        timer: 3000, // Set the timer for 3 seconds (3000 milliseconds)
      });
    } catch (error) {
      setIsUploading(false);
      console.error("Error uploading slip: " + error.message);
    }
  };

  useEffect(() => {
    document.title = "Payments - ICT Suhashi";
    setOrderId(generateRandomOrderId());
  }, [user]);

  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />

        <div className="h-screen max-w-4xl p-4 mx-auto">
          <div className="grid gap-8 md:grid-cols-2">
            <div>
              <h2 className="mb-2 text-2xl font-bold text-gray-900">
                Payment Information
              </h2>
              <table className="table">
                <tbody>
                  <tr>
                    <td className="text-left">Bank:</td>
                    <td className="text-left">Sampath Bank</td>
                  </tr>
                  <tr>
                    <td className="text-left">Acc No:</td>
                    <td className="text-left">107653384416</td>
                  </tr>
                  <tr>
                    <td className="text-left">Branch:</td>
                    <td className="text-left">Kandana</td>
                  </tr>
                  <tr>
                    <td className="text-left">Acc Holder Name:</td>
                    <td className="text-left">M.A.Y.S BANDARA</td>
                  </tr>
                </tbody>
              </table>
              <h2 className="mt-8 mb-2 text-2xl font-bold text-gray-900">
                Order Information
              </h2>
              <table className="table">
                <tbody>
                  <tr>
                    <td className="text-left">Date: </td>
                    <td className="text-left">
                      {new Date().toLocaleDateString("en-US")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left">Video Title: </td>
                    <td className="text-left">{title}</td>
                  </tr>
                  <tr>
                    <td className="text-left">Video Description: </td>
                    <td className="text-left">{description}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div>
              <div className="p-4 bg-gray-200 rounded-lg">
                <h3 className="mb-2 text-xl font-bold">{formattedPrice}</h3>
                <div className="mb-4">
                  <label htmlFor="email" className="block mb-2">
                    Email address for getting access
                  </label>
                  <label className="text-sm">{paymentInfo.email}</label>
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Upload Payment Slip</label>
                  <input
                    className="flex w-full h-10 px-3 py-2 text-sm border border-gray-300 rounded-md bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    id="fileupload"
                    type="file"
                    name="paymentSlip"
                    required
                    onChange={(e) => handleUpload(e)}
                  />
                </div>
                <button
                  disabled={isUploading || file == null}
                  className="inline-flex items-center justify-center w-full h-10 px-4 py-2 text-sm font-medium text-white transition-colors bg-blue-700 rounded ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-blue-800"
                  onClick={uploadSlip}
                >
                  {isUploading && (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 text-white me-3 animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                  )}
                  Upload Payment Slip &amp; Confirm The Payment
                </button>
              </div>
            </div>
          </div>
          <div className="px-2 py-1 mt-10 bg-orange-100 border rounded-lg">
            <p className="text-sm">
              Note: The payment may take up to 24 hours to be approved in
              Workday. After the payment is approved, you can access the lessons
              page to view the lessons.
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Payments;
