import React, {useEffect, useState} from 'react'
import inquiry_image from "./Assets/contact-us.jpg";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../Firebase/firebase-config";
import { UserAuth } from "../../Context/AuthContext";
import swal from "sweetalert";
import Sidebar1 from './components/Sidebar1';
import Navbar1 from './components/Navbar1';
import Footer from '../Home/Components/Footer';
const Inquires = () => {

  const initialState = {
    name: "",
    email: "",
    subject: "",
    message: ""
  }
  const {user} = UserAuth()
  const [inq, setInq] = useState(initialState);
  
  const tabName = "/ Inquires";

  useEffect(() => {
      document.title = "Inquires - ICTSuhashi";
    }, []);

  const currentDate = new Date().toLocaleDateString();
  const currentTime = new Date().toLocaleTimeString();
  const auth_user_id = user && user?.uid;
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };

  const handleInputChange = (event) => {
    event.persist();
    setInq({
      ...inq,
      [event.target.name]: event.target.value,
    });
  };
  const submitInqury = async (event) => {
    event.preventDefault();
    try{
      await addDoc(collection(db, "inquiries"), {
        userId: auth_user_id,
        name: inq.name,
        email: inq.email,
        title: inq.subject,
        message: inq.message,
        time: currentTime,
        date: currentDate,
      });
      swal(
        "Success",
        "Inquiry Submitted Successfully!\nwill get back to you as soon as possible.",
        "success"
      );

      setTimeout(() => {
        window.location.reload();
      }, 4000);

    }
    catch (error){
      console.error("Something went wrong: " + error.message);
    }
  }

  useEffect(() => {
    document.title = "Inquiries - ICT Suhashi";
  }, []);

  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
      <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />

        <div className="container min-h-screen px-4 mx-auto my-2">
          <div className="grid items-stretch h-full md:gap-4 md:grid-cols-2">
            <div className="bg-[#ffffff] md:p-8 md:block hidden">
              <img
                src={inquiry_image}
                alt="Inquiry Illustration"
                className="object-cover w-full h-full"
                width="500"
                height="500"
                style={{ aspectRatio: "500 / 500", objectFit: "cover" }}
              />
            </div>
            <div className="bg-white md:p-8">
              <div
                className="h-full border rounded-lg shadow-sm bg-card text-card-foreground"
                data-v0-t="card">
                <div className="flex flex-col space-y-1.5 p-6">
                  <h2 className="text-xl font-bold text-gray-800">
                    Make an Inquiry
                  </h2>
                  <p className="text-gray-600">
                    Please fill out the form below and we will get back to you
                    as soon as possible.
                  </p>
                </div>
                <div className="p-6">
                  <form className="space-y-6">
                    <div className="grid items-center gap-1.5">
                      <label
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        htmlFor="name">
                        Name
                      </label>
                      <input
                        className="flex w-full h-10 px-3 py-2 text-sm border rounded-md border-input bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        id="name"
                        placeholder="Your Name"
                        type="text"
                        name="name"
                        value={inq.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid items-center gap-1.5">
                      <label
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        htmlFor="email">
                        Email
                      </label>
                      <input
                        className="flex w-full h-10 px-3 py-2 text-sm border rounded-md border-input bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        id="email"
                        placeholder="Your Email"
                        type="email"
                        name="email"
                        value={inq.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid items-center gap-1.5">
                      <label
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        htmlFor="subject">
                        Subject
                      </label>
                      <input
                        className="flex w-full h-10 px-3 py-2 text-sm border rounded-md border-input bg-background ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                        id="email"
                        placeholder="Subject"
                        type="text"
                        name="subject"
                        value={inq.subject}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid items-center gap-1.5">
                      <label
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        htmlFor="message">
                        Your Message
                      </label>
                      <textarea
                        id="message"
                        rows="4"
                        className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                        placeholder="Type your message..."
                        name="message"
                        value={inq.message}
                        onChange={handleInputChange}></textarea>
                    </div>

                    <button
                      className="inline-flex items-center justify-center w-full h-10 px-4 py-2 mt-4 text-sm font-medium text-white transition-colors bg-blue-700 rounded-lg ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 hover:bg-blue-800"
                      onClick={submitInqury}>
                      Submit Inquiry
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default Inquires;