import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../Assets/Logo_white.png'
import call from '../Assets/icons8-call-50.png'
import whatsapp from '../Assets/icons8-whatsapp-48.png'
import gmail from '../Assets/icons8-gmail-48.png'

const Footer = () => {
  return (
    <footer className="flex flex-col items-center w-full pt-5 text-white bg-gray-900">
      <div className='flex flex-col w-full md:gap-5 md:px-10 md:flex-row lg:w-4/5'>
        {/* <div className='flex items-center justify-center h-full mb-5 md:mb-0'>
          <Link to='/'>
          <img src={logo} alt='logo' className='w-28'/>
          </Link>
        </div> */}
        <div className='flex flex-col w-full gap-5 pb-5 md:flex-row md:gap-5'>
          <div className='flex flex-col items-center w-full md:block'>
            <h3 className='pb-2 text-md md:text-lg opacity-[75%]'>CONTACT</h3>
            <div className='flex items-center gap-1 mb-1 md:gap-2'>
              <img alt='call' src={call} className='w-3 md:w-5'/>
              <a className='text-[12px] md:text-[13px] mb-1' href="tel:+947732456636">+94 77 32456636</a>
            </div>
            <div className='flex items-center gap-1 mb-1 md:gap-2'>
              <img alt='gmail' src={gmail} className='w-3 md:w-5'/>
              <a className='text-[12px] md:text-[13px] mb-1' href="mailto:ictsuhashi@gmail.com">ictsuhashi@gmail.com</a>
            </div>
            <div className='flex items-center gap-1 mb-1 md:gap-2'>
              <img alt='whatsapp' src={whatsapp} className='w-3 md:w-5'/>
              <a className='text-[12px] md:text-[13px] mb-1' href="https://wa.me/07732456636">+94 77 32456636</a> 
            </div>
          </div>
          
          <div className='flex flex-col items-center w-full md:block'>
            <h3 className='pb-2 text-md md:text-lg opacity-[75%]'>QUICK LINKS</h3>
            <Link to='/manage-courses'>
            <p className='text-[12px] md:text-[13px] mb-1'>Grades</p>
            </Link>
            </div>
          <div className='flex flex-col items-center w-full md:block'>
            <h3 className='pb-2 text-md md:text-lg opacity-[75%]'>MORE</h3>
            <Link to='/terms-conditions'> <p className='text-[12px] md:text-[13px] mb-1'>Terms and Conditions</p></Link>
            <Link to='/privacy-policy'><p className='text-[12px] md:text-[13px] mb-1'>Privacy Policy</p></Link>
            <Link to='/about'><p className='text-[12px] md:text-[13px] mb-1'>About Us</p></Link>
            <Link to='/contact-us'><p className='text-[12px] md:text-[13px] mb-1'>Contact Us</p></Link>
            </div>
            <div className='flex flex-col items-center w-full md:block'>
            <h3 className='pb-2 text-md md:text-lg opacity-[75%]'>FIND US ON</h3>
            <div className='flex items-center gap-1 mb-1 md:gap-1'>
              <img alt='call' src="https://img.icons8.com/color/96/youtube-play.png" className='w-3 md:w-5'/>
              <a className='text-[12px] md:text-[13px] mb-1' href="https://www.youtube.com/channel/UCRxhm_2R3pxuYsrfZIRdLZQ">Suhashinee Bandara  ICT Lessons</a>
            </div>
            {/* <div className='flex items-center gap-1 mb-1 md:gap-2'>
              <img alt='gmail' src="https://img.icons8.com/color/96/facebook-new.png" className='w-5'/>
              <a href="mailto:ictsuhashi@gmail.com">ictsuhashi@gmail.com</a>
            </div> */}
          </div>
        </div>
      </div>
      <hr className="w-full mt-5 bg-gray-500 opacity-[25%] md:mt-0" />
        <div className="border-gray-100 ">
          {/* <p className="text-sm text-center text-gray-500">
            © ICT-Suhashi 2024. All rights reserved.
          </p> */}
          <p className="text-center text-[12px] md:text-[13px] text-white">
            © ICT-Suhashi 2024. All rights reserved.
            Design & Developed by
            <a
              href="https://silverlineit.co/"
              className="ml-1 text-white underline transition hover:text-gray-700/75">
              SilverlineIT
            </a>
          </p>
        </div>
    </footer>

  );
}

export default Footer;