import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom'; // Assuming you're using react-router-dom for navigation

const HomeCarousel = ({ home_images }) => {
  // Carousel settings
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite loop
    speed: 1000, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Autoplay interval in milliseconds
    pauseOnHover: true, // Pause autoplay on hover
  };

  return (
    <div className="relative w-full overflow-hidden h-52 md:h-96">
      <Slider {...settings}>
        {home_images.map((image, index) => (
          <div key={index}>
            <div
              className="relative w-full overflow-hidden h-52 md:h-96"
              style={{
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'top',
                backgroundRepeat: 'no-repeat',
              }}
            >
              {/* Black overlay */}
              <div className="absolute inset-0 bg-black opacity-40"></div>

              {/* Content */}
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="flex flex-col items-center">
                  <h2 className="text-3xl font-bold text-white md:text-8xl">
                    LEARN EVERYTHING
                  </h2>
                  <p className="mt-3 text-sm font-bold text-[16px] md:text-[24px] text-center md:text-left text-white">
                    Online Courses - Interactive Tuition Classes - A/L, O/L Lessons
                  </p>
                  <Link to="/manage-courses">
                    <button className="px-4 py-2 bg-[#1C79BF] hover:text-white rounded-md mt-1 md:mt-10 md:text-2xl md:px-5 md:py-3">
                      See Courses
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeCarousel;