import { Route, Routes } from "react-router-dom";
import Login from "../Components/Login/Login";
import Signup from "../Components/Signup/Signup";
import Dashboard from "../Components/Dashboard/Dashboard";
import Overview from "../Components/Dashboard/Overview";
import Payments from "../Components/Dashboard/Payments";
import ManageCourses from "../Components/Dashboard/ManageCourses";
import ExploreCourses from "../Components/Dashboard/ExploreCourses";
import Papers from "../Components/Dashboard/Papers";
import SystemSetting from "../Components/Dashboard/SystemSetting";
import HelpCenter from "../Components/Dashboard/HelpCenter";
import Videos from "../Components/Dashboard/Videos";
import Courses from "../Components/Dashboard/Courses";
import Chapters from "../Components/Dashboard/Chapters";
import PaymentHistoryList from "../Components/Dashboard/PaymentHistoryList";
import PaymentHistory from "../Components/Dashboard/PaymentHistory";
import Protected from "./Protected";
import VideoViewer from "../Components/Dashboard/VideoViewer";
import Inquires from "../Components/Dashboard/Inquires";
import EduLevelList from "../Components/Dashboard/EduLevelList";
import CourseList from "../Components/Dashboard/CourseList";
import ChapterList from "../Components/Dashboard/ChapterList";
import TermsConditons from "../Components/Home/TermsConditions";
import PrivacyPolicy from "../Components/Home/PrivacyPolicy";
import PaperPayments from "../Components/Dashboard/PaperPayments";
import Home from "../Components/Home/Home";
import AboutTutor from './../Components/Home/AboutTutor';
import PaperCollection from "../Components/Dashboard/PaperCollection";
import NewPapers from "../Components/Dashboard/NewPaper";
import PaymentHistoryPaper from "../Components/Dashboard/PaymentHistoryPaper";
import ContactUs from "../Components/Home/ContactUs";



const RouteList = () => {
  const routes = [
    // {
    //   path: "/",
    //   element: <Login />,
    // },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/terms-conditions",
      element: <TermsConditons />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/signup",
      element: <Signup />,
    },
    {
      path: "/about",
      element: <AboutTutor />
    },
    {
      path: "/contact-us",
      element: <ContactUs />
    },
    {
      path: "/dashboard",
      element: (
        <Protected>
          <Dashboard />
        </Protected>
      ),
    },
    {
      path: "/overview",
      element: (
        <Protected>
          <Overview />
        </Protected>
      ),
    },
    {
      path: "/manage-courses",
      element: (
        <Protected>
          <ManageCourses />
        </Protected>
      ),
    },
    {
      path: "/explore-courses",
      element: (
        <Protected>
          <ExploreCourses />
        </Protected>
      ),
    },
    {
      path: "/papers",
      element: (
        <Protected>
          <EduLevelList />
        </Protected>
      ),
    },
    {
      path: "paper-collection/:collectionId/papers",
      element: (
        <Protected>
        <NewPapers />
      </Protected>
      )
    },
    {
      path: "paper-collection/",
      element: (
        <Protected>
        <PaperCollection />
      </Protected>
      )
    },
    {
      path: "/papers/:eduLevelId/courses",
      element: (
        <Protected>
          <CourseList />
        </Protected>
      ),
    },

    {
      path: "/papers/:eduLevelId/courses/:courseId/chapters",
      element: (
        <Protected>
          <ChapterList />
        </Protected>
      ),
    },
    {
      path: "/papers/:eduLevelId/courses/:courseId/chapters/:chapterId/downloads",
      element: (
        <Protected>
          <Papers />
        </Protected>
      ),
    },
    // {
    //   path: "/notes",
    //   element: (
    //     <Protected>
    //       <Notes />
    //     </Protected>
    //   ),
    // },
    {
      path: "/notes",
      element: (
        <Protected>
          <Courses />
        </Protected>
      ),
    },
    {
      path: "/system-settings",
      element: (
        <Protected>
          <SystemSetting />
        </Protected>
      ),
    },
    {
      path: "/help-center",
      element: (
        <Protected>
          <HelpCenter />
        </Protected>
      ),
    },
    {
      path: "/courses/:grade/:id",
      element: (
        <Protected>
          <Courses />
        </Protected>
      ),
    },
    {
      // path: "/chapters/:grade/:course/:id",
      path: "/chapters/:eduLevelId/:courseId",
      element: (
        <Protected>
          <Chapters />
        </Protected>
      ),
    },
    {
      // path: "/videos/:grade/:course/:chaptername/:id",
      path: "/videos/:eduLevelId/:courseId/:chapterId",
      element: (
        <Protected>
          <Videos />
        </Protected>
      ),
    },
    {
      // path: "/payments/:grade/:course/:chaptername/:videoDocID/:videoId/:price",
      // path: "/payments/:videoDocID/:videoId/:price/:title",
      path: "/payments/:lessonId/:driveFolderId/:price/:description/:title",
      element: (
        <Protected>
          <Payments />
        </Protected>
      ),
    },
    {
      
      path: "/paper-payments/:paperId/:title/:price/:description",
      element: (
        <Protected>
          <PaperPayments />
        </Protected>
      ),
    },
    {
      path: "/payment-history-list",
      element: (
        <Protected>
          <PaymentHistoryList />
        </Protected>
      ),
    },
    {
      path: "/payment-history/:orderID",
      element: (
        <Protected>
          <PaymentHistory />
        </Protected>
      ),
    },
    {
      path: "/payment-history-paper/:orderID",
      element: (
        <Protected>
          <PaymentHistoryPaper />
        </Protected>
      ),
    },
    {
      // path: "/videos/:chapter_id/:videoId",
      path: "/video/:eduLevelId/:courseId/:chapterId/:videoId",
      element: (
        <Protected>
          <VideoViewer />
        </Protected>
      ),
    },
    {
      path: "/inquiries",
      element: <Inquires />,
    },
    {
      path: "/terms-and-conditions",
      element: <TermsConditons />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
  ];

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}

export default RouteList;