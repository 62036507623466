import React, {useEffect} from "react";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";


const ExploreCourses = () => {

  const tabName = "/ Explore Courses";

   useEffect(() => {
        document.title = "Explore Courses - ICTSuhashi";
      }, []);
  
  return (
    <div className="flex">
      <Sidebar1 />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} />

        <div className="container px-4 mx-auto my-2">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <div className="rounded-lg border text-card-foreground shadow-sm bg-[#a0f0ed]">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  10
                </h3>
                <p className="text-sm text-muted-foreground">Total Course</p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#d9a8ff]"
              data-v0-t="card">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  10
                </h3>
                <p className="text-sm text-muted-foreground">Total Notes</p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#ffccac]"
              data-v0-t="card">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  8/10
                </h3>
                <p className="text-sm text-muted-foreground">
                  Average Quiz Score
                </p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
            <div
              className="rounded-lg border text-card-foreground shadow-sm bg-[#ace0f9]"
              data-v0-t="card">
              <div className="flex flex-col space-y-1.5 p-6">
                <h3 className="text-2xl font-semibold leading-none tracking-tight">
                  8
                </h3>
                <p className="text-sm text-muted-foreground">Finished Units</p>
              </div>
              <div className="flex items-center p-6">
                <button className="inline-flex items-center justify-center rounded-md text-sm font-medium text-white ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-[#35342f] hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2">
                  See Details
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default ExploreCourses;
