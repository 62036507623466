import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from "./../../Firebase/firebase-config";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import Footer from "./../Home/Components/Footer";
import { FaDownload } from "react-icons/fa6";
import { MdAssignment } from "react-icons/md";
import { UserAuth } from '../../Context/AuthContext';

const NewPapers = () => {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [paperPaymentStatus, setPaperPaymentStatus] = useState([]);
  const [userId, setUserId] = useState("");
  const { user } = UserAuth();

  useEffect(() => {
    setUserId(user.uid);
    
  }, [user]);
  const showSideBar = () => {
    setSidebarVisible(true);
  };

 

  const { collectionId } = useParams();

  const tabName = "/ Paper Collection / Papers";

    useEffect(() => {
          document.title = "Papers - ICTSuhashi";
        }, []);

  const fetchPaperPaymentStatus = async () => {
    try {
      if (userId) {
        const paperPaymentCollection = collection(db, "payments_paper");
        const q = query(paperPaymentCollection, where("userId", "==", userId));
        const querySnapshot = await getDocs(q);
        const paperPaymentStatusData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPaperPaymentStatus(paperPaymentStatusData);
      }
    } catch (error) {
      console.error("Error fetching payment history:", error);
    }
  };

  useEffect(()=>{
    fetchPaperPaymentStatus()
  },[userId])

  useEffect(() => {
    setLoading(true);
    const fetchPapers = async () => {
      try {
        const paperSnapshot = await getDocs(
          collection(db, `paper-collections/${collectionId}/papers`)
        );

        const papers = await paperSnapshot.docs.map((paper) => ({
          id: paper.id,
          data: paper.data(),
        }));
        setPapers(papers);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchPapers();
    
  }, [collectionId]);

  const downloadFile = async (url) => {
    const popupWindow = window.open(url, "PDFPopup", "width=600,height=800");
    if (!popupWindow) {
      alert("Popup blocked. Please allow popups for this site.");
    }
  };

  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {papers?.length === 0 && !loading ? (
          <div className="flex flex-col items-center mt-10">
            <p>No data available. Please check back later.</p>
          </div>
        ) : (
          ""
        )}
        <div className="min-h-screen px-4 mx-auto my-2 ">
          <div className="grid">
            {papers
              ?.sort((a, b) => {
                //Assuming 'dateTime' is a property of each edulevelDoc
                const dateTimeA = new Date(`${a.data.date} ${a.data.time}`);
                const dateTimeB = new Date(`${b.data.date} ${b.data.time}`);
                // return dateTimeB - dateTimeA;
                return dateTimeA - dateTimeB;
              })
              ?.map((paperDoc) => (
                <div
                  key={paperDoc.id}
                  className="flex flex-col items-center justify-center flex-1 w-full grid-cols-4 gap-3 p-4 rounded shadow-md md:grid"
                >
                  {/* <span>{paperDoc.id}</span> */}
                  <div className="flex items-center justify-center w-full gap-3 md:justify-start">
                    <MdAssignment />
                    {paperDoc.data.title}
                  </div>
                  <div className="w-full text-center md:text-left">
                    {paperDoc.data.description}
                  </div>

                  {paperDoc.data.price === 0 ? (
                    <span className="w-24 bg-red-100 text-center  border  text-sm font-semibold px-2.5 py-0.5 rounded ">
                      Free
                    </span>
                  ) : (
                    <span className="bg-green-100 w-24 border text-center text-sm font-semibold px-2.5 py-0.5 rounded  ">
                      LKR {paperDoc.data.price}
                    </span>
                  )}

                  <div className="flex justify-center w-full md:justify-end ">
                    {paperDoc.data.price === 0 ? (
                      <>
                        <div
                          className="flex items-center justify-center py-2 text-sm font-semibold bg-green-300 border rounded cursor-pointer w-44"
                          onClick={() => {
                            downloadFile(paperDoc.data.url);
                          }}
                        >
                          <FaDownload className="lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-2 sm:h-2" />
                          <span className="pl-1 ">Download</span>
                        </div>
                      </>
                    ) : (
                      <>
                        {paperPaymentStatus.some(
                          (ps) => ps.paperId === paperDoc.id
                        ) ? (
                          <>
                            {paperPaymentStatus.find(
                              (ps) => ps.paperId === paperDoc.id
                            ).isAccept ? (
                              <>
                                <div
                                  className="flex items-center justify-center py-1 py-2 text-sm font-semibold bg-green-300 border rounded cursor-pointer md:w-44 "
                                  onClick={() => {
                                    downloadFile(paperDoc.data.url);
                                  }}
                                >
                                  <FaDownload className="lg:w-5 lg:h-5 md:w-5 md:h-5 sm:w-2 sm:h-2" />
                                  <span className="ml-1">Download</span>
                                </div>
                              </>
                            ) : (
                              <Link className="py-2 text-sm font-semibold text-center bg-red-300 rounded w-44">
                                Approval Pending
                              </Link>
                            )}
                          </>
                        ) : (
                          <Link
                            to={`/paper-payments/${
                              paperDoc.id
                            }/${encodeURIComponent(paperDoc.data.title)}/${
                              paperDoc.data.price
                            }/${encodeURIComponent(paperDoc.data.description)}`}
                            className="py-2 text-sm font-semibold text-center bg-blue-300 border rounded w-44"
                          >
                            Buy Paper
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default NewPapers;
