import React from 'react'

const Navbar1 = ({TabName, onShowSideBar }) => {
  return (
    <div className="z-40 flex flex-col overflow-hidden">
      <header className="flex items-center justify-between px-4 pb-4 bg-white border-b border-gray-200 md:p-4">
        <div className="flex items-center space-x-4">
          <button onClick={onShowSideBar } className=" lg:hidden text-gray-500 border rounded-md focus:outline-none  px-2 py-1.5 overflow-hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-6 h-6">
              <line x1={4} x2={20} y1={12} y2={12} />
              <line x1={4} x2={20} y1={6} y2={6} />
              <line x1={4} x2={20} y1={18} y2={18} />
            </svg>
          </button>
          <h1 className="text-lg font-semibold text-gray-800">
            Dashboard {TabName}
          </h1>
        </div>
      </header>
    </div>
  );
}

export default Navbar1;