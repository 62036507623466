import React, {useEffect} from 'react'

import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import terms_image from './Assets/terms.jpg'

const TermsConditons = () => {

   useEffect(()=>{
        document.title = "Termas & Conditions - ICTSuhashi";

        // Set the canonical URL
    const canonicalLink = document.createElement('link');
    canonicalLink.rel = "canonical";
    canonicalLink.href = window.location.href; // Use the current URL or your preferred canonical URL
    document.head.appendChild(canonicalLink);

    // Clean up the meta description and canonical link when the component unmounts
    return () => {
      document.head.removeChild(canonicalLink);
    };
      },[])
  return (
    <div className='flex flex-col justify-between h-screen'>
      <div>
      <Navbar/>
      {/* <div className='relative flex items-center justify-center w-full h-auto overflow-hidden bg-black md:h-52'>
            <img className='w-full opacity-40 cover-fill' alt='' src={terms_image}/>

            <div className='absolute'>
                <h1 className='text-4xl font-bold text-white'>Terms and Conditions</h1>
                <p className='text-sm font-bold text-white'>These Terms of Use (“Terms”) were last updated on May 1, 2024.</p>
            </div>
        </div> */}

<div className='relative w-full overflow-hidden h-52 md:h-96' style={{ backgroundImage: `url(${terms_image})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundColor: 'black' }}>

{/* Black overlay */}
<div className='absolute inset-0 bg-black opacity-40'></div>

{/* Content */}
<div className='absolute inset-0 flex flex-col items-center justify-center px-4 md:px-0'>
    <div className='flex flex-col items-center'>
        <h1 className='text-4xl font-bold text-white'>Terms & Conditions</h1>
        <p className='text-xs font-bold text-center text-white md:text-sm'>These Terms of Use (“Terms”) were last updated on May 1, 2024.</p>
    </div>
</div>
</div>

      <div className="flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <div className='flex justify-center w-full py-5'>
          <h1 className='text-2xl'>Terms of Use</h1>
        </div>

        <div className="container px-4 mx-auto mb-5">
          <p className="pb-1 mx-auto text-gray-800 ">
            ICTSuhashi's mission is to facilitate the sharing of knowledge and experience in Local O/L ,A/L ICT and Edexcel  International AS , A2 IT lessons. Through our platform, we aim to create a digital environment where students can access high-quality educational content and participate in online meetups, breaking down barriers to learning. Our goal is to empower students to enroll in educational courses created by educators, thus providing them with valuable learning opportunities from anywhere in the world. We believe our marketplace model is the most effective way to deliver these educational benefits to our student users.
          </p>
          <p className="mx-auto text-gray-800 ">
            To ensure the integrity of our platform and services for all users, including students, we have established guidelines that must be adhered to by everyone utilizing ICTSuhashi. These Terms apply to all individuals who use ICTSuhashi in any capacity, including accessing the website, mobile applications, and TV applications.
          </p>
          <h3 className='py-3 text-xl'>Accounts</h3>
          <p className="pb-1 mx-auto text-gray-800 ">
          For most of the services offered by ICTSuhashi, you are required to create an account providing your personal information, which will be safeguarded by a password or secret key chosen by you. We strongly advise you to keep your password or secret key in a secure place, as you are responsible for all activities associated with your account.If you suspect that someone else is using your account without authorization, please inform us immediately by reaching out to our support team, if available, or through any other means of contacting us, such as via Facebook Messenger.To access ICTSuhashi's online services, you must meet the age of consent required in your country to use online services.
          </p>
          <p className="pb-1 mx-auto text-gray-800 ">
          As a student enrolling in and purchasing a course on ICTSuhashi, it is necessary to create an account with accurate and complete information, including a valid email address. You are solely responsible for maintaining your account and all activities conducted within it. This includes any potential harm or damage caused to us or any other individual due to unauthorized use of your account. Therefore, it is crucial to handle your password or secret key with care.You are not permitted to transfer your account to another individual or use someone else's account. If you reach out to us to request access to an account, we will only grant such access if you can provide the required information to verify that you are the rightful owner of the account.
          </p>
          <p className="pb-1 mx-auto text-gray-800 ">
          You are strictly prohibited from sharing your account login details with any other individual. You are solely responsible for all activities that occur within your account, and ICTSuhashi will not intervene in any disputes between users who have shared their account login details. If you suspect that another person may be using your account without authorization or if you detect any security breaches, it is imperative that you notify us promptly through our support team. In such cases, we may request additional information from you to confirm that you are the rightful owner of the account.
          </p>
          <p className="mx-auto text-gray-800 ">
          You can terminate your ICTSuhashi account at any time by following the provided steps. Please refer to our privacy policy to understand what happens after terminating your account.
          </p>
          <h3 className='py-3 text-xl'>Course Enrollment and 6 month period Access</h3>
          <p className="pb-1 mx-auto text-gray-800 ">
          When you enroll in a course on ICTSuhashi, you are granted a license from us to access the course content through our services. It is strictly prohibited to attempt to transfer or exchange courses in any manner. We provide you with a license for 6 month period access, except in cases where we are required to disable the course due to legal or policy reasons.
          </p>
          <p className="pb-1 mx-auto text-gray-800 ">
          In legal terms, ICTSuhashi grants you (as a student) a limited, non-exclusive, non-transferable license to access and view the courses and related content for which you have paid all required fees, solely for your own non-commercial, educational purposes through the Services, in accordance with these Terms and any conditions or limitations associated with specific courses or features of our Services. 
All other uses are explicitly prohibited. You may not reproduce, broadcast, assign, redistribute, transmit, sell, share, lend, modify, rent, adapt, alter, create derivative works of, sublicense, or engage in any similar actions without explicit permission.
          </p>
          <p className="mx-auto text-gray-800 ">
          ICTSuhashi typically provides students with a 6 month period access license upon enrollment in a course. However, the platform reserves the right to deny access and use licenses to courses if necessary, such as in cases where disabling access is mandated by policy or legal reasons, such as copyright complaints or violations of Trust and Safety Guidelines. It's important to note that while the 6 month period access license applies to course content, it does not extend to additional features and services associated with the course.
          </p>
          <h3 className='py-3 text-xl'>Payments, Credits and refunds</h3>
          <h4 className='py-3'>Pricing</h4>
          <p className="pb-1 mx-auto text-gray-800 ">The prices of courses on ICTSuhashi are determined in accordance with the terms outlined in the Educator Terms and our Promotions Policy. We frequently offer promotions and sales for our courses, with some courses available at discounted prices for a specified duration. The price you see at the time of checkout will be applicable to the course you are purchasing.
          </p>
          <p>
          Country-based sales tax, goods and services tax, or value-added tax may be applicable to consumer sales on ICTSuhashi. The price you see for a course may include such taxes, or taxes may be added at the checkout.
          </p>
          <h4  className='py-3'>Payments</h4>
          <p className="pb-1 mx-auto text-gray-800 ">
          You agree to pay the fees for courses that you purchase on ICTSuhashi, and you authorize us to charge your chosen payment method for those fees. ICTSuhashi offers various payment methods, including manual payment options, to provide you with the most convenient options based on your country. We work with trusted third-party payment processing partners to ensure the security of your payment information. Please refer to our Privacy Policy for more information.
          </p>
          <p className="mx-auto text-gray-800 ">
          By enrolling in a course, you acknowledge and agree that the enrollment process requires a valid and authorized payment method. You further agree that access to the course will only be granted upon successful completion of the payment process. ICTSuhashi retains the right to revoke access to any course until satisfactory payment has been received.
          </p>
          <h4  className='py-3'>Refunds</h4>
          <p className="mx-auto text-gray-800 ">If the course you purchased on ICTSuhashi does not meet your expectations, please note that detailed descriptions of the content are provided. It is the responsibility of the customer to select the correct product according to the given description. Refunds will not be granted for incorrect selections made by the customer.</p>
        </div>
      </div>
      </div>
      <Footer/>
    </div>
  );
}

export default TermsConditons;