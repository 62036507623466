// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { UserAuth } from "../../../Context/AuthContext";
// import logo from "../Assets/Logo.png";
// function Navbar() {
//   const { user, logout } = UserAuth();
//   const [ isVisible, setIsVisible] = useState(false);
//   console.log(user);

//   const handleLogout = async (event) => {
//     event.preventDefault();
//     try {
//       await logout();
//     } catch (handledError) {}
//   };


//   const toggleNavbar = () => {
//     setIsVisible(!isVisible)
//   };


//   return (
//     // <div className='flex items-center justify-between w-full h-16 px-3 border-b md:px-10 lg:px-56'>
//     <>
//       <div className="flex items-center justify-between w-full h-[70px] px-3 border-b 2xl:px-52">
//         <Link to="/">
//           <div className="flex items-center gap-2">
//             <img src={logo} alt="logo" width="48px" height="48px"></img>
//             <h1 className="text-2xl font-bold text-[#1C79BF]">ICTSuhashi</h1>
//           </div>
//         </Link>
//         <div className="hidden md:block">
//           <ul className="flex gap-12">
//             <Link to="/" className="text-xl font-medium hover:underline">
//               Home
//             </Link>
//             <Link
//               to="/overview"
//               className="text-xl font-medium hover:underline"
//             >
//               Dashboard
//             </Link>
//             <Link to="/about" className="text-xl font-medium hover:underline">
//               About Us
//             </Link>
//             <Link
//               to="/contact-us"
//               className="text-xl font-medium hover:underline"
//             >
//               Contact Us
//             </Link>
//           </ul>
//         </div>

//         <div className="hidden md:flex gap-7">
//           {/* <Link  to='/login' className='items-center hidden hover:underline md:flex md:text-[16px]'>
//             Login
//         </Link> */}
//           {user ? (
//             <Link
//               onClick={handleLogout}
//               className="bg-[#1C79BF] hover:text-white text-[18px] px-5 py-1.5 rounded-md  flex items-center"
//             >
//               Logout
//             </Link>
//           ) : (
//             <Link
//               to="/login"
//               className="bg-[#1C79BF] hover:text-white text-[18px] px-5 py-1.5 rounded-md  flex items-center"
//             >
//               SignIn
//             </Link>
//           )}
//         </div> 
//         <div className="flex overflow-hidden gap-7 lg:hidden">
//           <button onClick={toggleNavbar} className="text-gray-500 border rounded-md focus:outline-none  px-2 py-1.5 overflow-hidden">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width={24}
//               height={24}
//               viewBox="0 0 24 24"
//               fill="none"
//               stroke="currentColor"
//               strokeWidth={2}
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               className="w-6 h-6"
//             >
//               <line x1={4} x2={20} y1={12} y2={12} />
//               <line x1={4} x2={20} y1={6} y2={6} />
//               <line x1={4} x2={20} y1={18} y2={18} />
//             </svg>
//           </button>
//         </div>
//       </div>
//       <div className={`absolute z-50 items-center justify-center w-full px-10 top-20 ${isVisible?'flex':'hidden'}`}>
//         <ul className="flex flex-col items-center w-full gap-12 py-10 rounded-md bg-blue-50">
//           <Link to="/" className="text-xl font-medium hover:underline">
//             Home
//           </Link>
//           <Link to="/overview" className="text-xl font-medium hover:underline">
//             Dashboard
//           </Link>
//           <Link to="/about" className="text-xl font-medium hover:underline">
//             About Us
//           </Link>
//           <Link
//             to="/contact-us"
//             className="text-xl font-medium hover:underline"
//           >
//             Contact Us
//           </Link>
//           {user ? (
//             <Link
//               onClick={handleLogout}
//               className="text-xl font-medium hover:underline"
//             >
//               Logout
//             </Link>
//           ) : (
//             <Link to="/login" className="text-xl font-medium hover:underline">
//               SignIn
//             </Link>
//           )}
//         </ul>
//       </div>
//     </>
//   );
// }

// export default Navbar;


import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserAuth } from "../../../Context/AuthContext";
import logo from "../Assets/Logo.png";

function Navbar() {
  const { user, logout } = UserAuth();
  const [isVisible, setIsVisible] = useState(false);
  const location = useLocation(); // Get current route

  const handleLogout = async (event) => {
    event.preventDefault();
    try {
      await logout();
    } catch (handledError) {}
  };

  const toggleNavbar = () => {
    setIsVisible(!isVisible);
  };

  // Function to check if the link is active
  const isActive = (path) => location.pathname === path ? "text-[#1C79BF] font-bold" : "text-black";

  return (
    <>
      <div className="flex items-center justify-between w-full h-[70px] px-3 border-b 2xl:px-52">
        <Link to="/">
          <div className="flex items-center gap-2">
            <img src={logo} alt="logo" width="48px" height="48px"></img>
            <h1 className="text-2xl font-bold text-[#1C79BF]">ICTSuhashi</h1>
          </div>
        </Link>
        <div className="hidden md:block">
          <ul className="flex gap-12">
            <Link to="/" className={`text-xl font-medium hover:underline ${isActive("/")}`}>
              Home
            </Link>
            <Link to="/overview" className={`text-xl font-medium hover:underline ${isActive("/overview")}`}>
              Dashboard
            </Link>
            <Link to="/about" className={`text-xl font-medium hover:underline ${isActive("/about")}`}>
              About Us
            </Link>
            <Link to="/contact-us" className={`text-xl font-medium hover:underline ${isActive("/contact-us")}`}>
              Contact Us
            </Link>
          </ul>
        </div>

        <div className="hidden md:flex gap-7">
          {user ? (
            <Link
              onClick={handleLogout}
              className="bg-[#1C79BF] hover:text-white text-[18px] px-5 py-1.5 rounded-md flex items-center"
            >
              Logout
            </Link>
          ) : (
            <Link
              to="/login"
              className="bg-[#1C79BF] hover:text-white text-[18px] px-5 py-1.5 rounded-md flex items-center"
            >
              SignIn
            </Link>
          )}
        </div>

        <div className="flex overflow-hidden gap-7 lg:hidden">
          <button onClick={toggleNavbar} className="text-gray-500 border rounded-md focus:outline-none px-2 py-1.5 overflow-hidden">
            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="w-6 h-6">
              <line x1={4} x2={20} y1={12} y2={12} />
              <line x1={4} x2={20} y1={6} y2={6} />
              <line x1={4} x2={20} y1={18} y2={18} />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`absolute z-50 items-center justify-center w-full px-10 top-20 ${isVisible ? 'flex' : 'hidden'}`}>
        <ul className="flex flex-col items-center w-full gap-12 py-10 rounded-md bg-blue-50">
          <Link to="/" className={`text-xl font-medium hover:underline ${isActive("/")}`}>
            Home
          </Link>
          <Link to="/overview" className={`text-xl font-medium hover:underline ${isActive("/overview")}`}>
            Dashboard
          </Link>
          <Link to="/about" className={`text-xl font-medium hover:underline ${isActive("/about")}`}>
            About Us
          </Link>
          <Link to="/contact-us" className={`text-xl font-medium hover:underline ${isActive("/contact-us")}`}>
            Contact Us
          </Link>
          {user ? (
            <Link onClick={handleLogout} className="text-xl font-medium hover:underline">
              Logout
            </Link>
          ) : (
            <Link to="/login" className="text-xl font-medium hover:underline">
              SignIn
            </Link>
          )}
        </ul>
      </div>
    </>
  );
}

export default Navbar;
