import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../Context/AuthContext";
import Navbar from "../Home/Components/Navbar";
import Footer from "../Home/Components/Footer";

const Signup = () => {
  const { signup_user} = UserAuth();
 

  useEffect(() => {
      document.title = "Signup - ICTSuhashi";
    }, []);

  const initialState = {
    name: '',
    dob: '',
    grade: '',
    email: '',
    password: '',
  }
  
  const [signupInfo, setSignupInfo] = useState(initialState);
  const [errors, setErrors] = useState({});

  const handleUserInput = (e) => {
    e.persist();
    setSignupInfo({
      ...signupInfo,
      [e.target.name]: e.target.value,
    });

    // Check if the input name is "dob"
    if (e.target.name === "dob") {
      const input = e.target;
      const originalValue = input.value;
      let value = originalValue.replace(/\D/g, ""); // Remove non-numeric characters
      let cursorPosition = input.selectionStart;

      if (value.length >= 8) {
        value = value.slice(0, 8);
      }

      if (value.length >= 4) {
        value = `${value.slice(0, 4)}/${value.slice(4)}`;
        cursorPosition += 1;
      }

      if (value.length >= 7) {
        value = `${value.slice(0, 7)}/${value.slice(7)}`;
        cursorPosition += 1;
      }

      if (
        cursorPosition === input.selectionEnd &&
        originalValue.length > value.length
      ) {
        cursorPosition -= 1;
      }

      // Set the formatted value in the state and maintain cursor position
      setSignupInfo({
        ...signupInfo,
        [e.target.name]: value,
      });

      input.setSelectionRange(cursorPosition, cursorPosition);
    } else {
      // For other input fields, update the state normally
      setSignupInfo({
        ...signupInfo,
        [e.target.name]: e.target.value,
      });
    }
  }

  const isDateValid = (dob) => {
    // Validate if the date is in the format YYYY/MM/DD
    const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;

    if (!dateRegex.test(dob)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "Please enter a valid date in the format YYYY/MM/DD",
      }));
      return false;
    }

    // Extract year, month, and day from the DOB
    const [year, month, day] = dob.split("/").map(Number);

    // Calculate the user's age
    const currentDate = new Date();
    const userBirthDate = new Date(year, month - 1, day); // Note: months are 0-indexed in JavaScript Date

    // Check if the user is at least 5 years old
    const minAgeDate = new Date(currentDate);
    minAgeDate.setFullYear(minAgeDate.getFullYear() - 5);

    if (userBirthDate > currentDate || userBirthDate > minAgeDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dob: "You must be at least 5 years old.",
      }));
      return false;
    }

    return true;
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Validate Date of Birth
    if (!isDateValid(signupInfo.dob)) {
      return;
    }
    // validate the email
    if (!signupInfo.email || !/^\S+@\S+\.\S+$/.test(signupInfo.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address",
      }));
      return;
    }

    // Validate password 
    if (!signupInfo.password || signupInfo.password.length < 6) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters long",
      }));
      return;
    }
    const signup_data = {
      name: signupInfo.name,
      dob: signupInfo.dob,
      grade: signupInfo.grade,
      email: signupInfo.email,
      password: signupInfo.password,
    };

    try{
      await signup_user(signup_data);
    }catch(handledError){
      alert("Something went wrong!!\n please try again.");
    }
  }
  return (
    <>
    <Navbar/>
    
    <div className="grid items-center justify-center w-screen h-auto grid-flow-col">
      <div className="w-[340px] lg:w-[420px] md:w-[420px] flex flex-col  py-20">
      <div className="text-3xl font-semibold text-center text-black">
        Sign In
      </div>
          <p className="flex-none mt-5 text-center text-gray-400">
            Hi! Enter your details to create your account
          </p>

          <p className="flex-none pt-5 font-semibold">Your Name</p>

          <div className="flex-none">
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              className="w-full h-16 rounded-lg border-[2px] border-[#EEF0F4] border-solid pl-[20px]"
              value={signupInfo.name}
              onChange={handleUserInput}
            />
          </div>

          <span className="flex pt-5 gap-[90px] md:gap-[130px]">
            <p className="font-semibold">Date of Birth</p>
            <p className="font-semibold">Grade</p>
          </span>
          {errors.dob && <p className="text-red-500">{errors.dob}</p>}
          <div className="flex flex-row gap-5">
            <input
              type="text"
              name="dob"
              placeholder="YYYY/MM/DD"
              className="w-1/2 h-16 rounded-lg border-[2px] border-[#EEF0F4] border-solid pl-[20px]"
              value={signupInfo.dob}
              onChange={handleUserInput}
            />

            <input
              type="text"
              name="grade"
              placeholder="Enter Your Grade"
              className="w-1/2 h-16 rounded-lg border-[2px] border-[#EEF0F4] border-solid pl-[20px]"
              value={signupInfo.grade}
              onChange={handleUserInput}
            />
          </div>

          <p className="pt-5 font-semibold">Email Address</p>

          {errors.email && <p className="text-red-500">{errors.email}</p>}
          <input
            type="email"
            name="email"
            placeholder="Enter Your Email Address"
            className="w-full h-16 rounded-lg border-[2px] border-[#EEF0F4] border-solid pl-[20px]"
            value={signupInfo.email}
            onChange={handleUserInput}
          />

          <p className="pt-5 font-semibold">Password</p>
          {errors.password && <p className="text-red-500">{errors.password}</p>}
          <input
            type="password"
            name="password"
            placeholder="Enter Your Password"
            className="w-full h-16 rounded-lg border-[2px] border-[#EEF0F4] border-solid pl-[20px]"
            value={signupInfo.password}
            onChange={handleUserInput}
          />

          <div className="pt-10">
            <button
              type="submit"
              className="w-full h-16 bg-[#1C79BF] text-white rounded-lg"
              onClick={handleSignup}>
              Sign-up
            </button>
          </div>

          <div className="">
            <div className="flex justify-center gap-1 pt-5">
              <p className="text-[#8994A4]">Already have an account?</p>
              <Link to="/login" className="text-[#1C79BF] cursor-pointer">
                Login
              </Link>
            </div>
          </div>
    </div>
    </div>
    <Footer/>
    </>
  );
};

export default Signup;
