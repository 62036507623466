import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import googleImage from "../Dashboard/Assets/google.png";
import { UserAuth } from "../../Context/AuthContext";
import { auth, provider } from "../../Firebase/firebase-config";
import { signInWithPopup } from "firebase/auth";
import Navbar from "./../Home/Components/Navbar";
import Footer from "./../Home/Components/Footer";

const Login = () => {
  const navigate = useNavigate();
  const { signin_user } = UserAuth();
  const initialState = {
    email: "",
    password: "",
  };

  useEffect(() => {
    document.title = "Login - ICTSuhashi";
  }, []);

  const [loginInfo, setLoginInfo] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const handleUserInput = (e) => {
    e.persist();
    setLoginInfo({
      ...loginInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    // Validate email
    if (!loginInfo.email || !/^\S+@\S+\.\S+$/.test(loginInfo.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address",
      }));
      return;
    }

    // Validate password (you can add more complex rules)
    if (!loginInfo.password || loginInfo.password.length < 6) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "Password must be at least 6 characters long",
      }));
      return;
    }
    const signin_data = {
      email: loginInfo.email,
      password: loginInfo.password,
    };

    try {
      await signin_user(signin_data);
    } catch (handledError) {
      console.log(handledError);
    }
  };

  const handleClickContinueWithGoogle = async () => {
    setButtonDisabled(true);
    try {
      const data = await signInWithPopup(auth, provider);
      localStorage.setItem("email", data.user.email);
      navigate("/overview");
      setButtonDisabled(false);
    } catch (error) {
      setButtonDisabled(false);
      // Handle specific errors
      if (error.code === "auth/popup-closed-by-user") {
        console.log("User closed the authentication popup");
        // You can choose not to show any error message to the user in this case
      } else {
        // Handle other errors
        console.error("Sign-in error:", error);
      }
    }
  };

  return (
    <>
      <Navbar />
      <div className="grid items-center justify-center grid-flow-col px-5">
        <div className="w-[340px] lg:w-[420px] md:w-[420px] py-20 flex flex-col">
          <div className="w-full text-3xl font-semibold text-center text-black h-7">
            Sign Up
          </div>

          <p className="text-center mt-[50px] text-gray-400 hidden md:block lg:block">
            Welcome back! Please select to login
          </p>

          <div className="hidden md:block lg:block">
            <button
              disabled={isButtonDisabled}
              onClick={handleClickContinueWithGoogle}
              className="h-16 w-full   flex mt-5  justify-center items-center gap-2 bg-white rounded-lg border-solid border-[#EEF0F4] border-[2px] font-semibold"
            >
              <img src={googleImage} alt="google" className="flex w-5 h-5" />
              <span className="">Continue with Google</span>
            </button>
          </div>

          <div className="flex flex-row items-center gap-2 pt-5">
            <hr className="border-[#F4F5F8] border border-solid w-1/3 hidden md:block lg:block" />
            <p className="text-[#8994A4] w-full md:w-2/3 text-center hidden md:block lg:block">
              {" "}
              or login with email
            </p>
            <hr className="border-[#F4F5F8] border border-solid w-1/3 hidden md:block lg:block" />
          </div>

          <div className="flex flex-col gap-1 pt-3">
            <p className="flex font-semibold text-left ">Email Address</p>
            <input
              type="email"
              placeholder="Enter Your Email Address"
              className="w-full h-16 rounded-lg border-solid border-[#EEF0F4] border-[2px] pl-[20px] "
              name="email"
              value={loginInfo.email}
              onChange={handleUserInput}
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>

          <div className="flex flex-col gap-1 pt-5">
            <p className="flex font-semibold text-left">Password</p>
            <input
              type="password"
              placeholder="Enter Your Password"
              className="w-full h-16 rounded-lg border-solid border-[#EEF0F4] border-[2px] pl-[20px]"
              name="password"
              value={loginInfo.password}
              onChange={handleUserInput}
            />
            {errors.password && (
              <p className="text-red-500">{errors.password}</p>
            )}
          </div>

          <a
            href="link"
            className="text-[#1C79BF] flex flex-col  text-right underline"
          >
            Forgot your Password?
          </a>

          <button
            className="mt-5 gap-10 w-full h-16 bg-[#1C79BF] text-lg text-white justify-center items-center rounded-lg"
            onClick={handleLogin}
          >
            Login
          </button>

          <div className="flex flex-row items-center pt-5">
            <hr className="border-[#F4F5F8] border border-solid w-1/3 md:hidden lg:hidden " />
            <p className="text-[#8994A4] w-1/3 text-center md:hidden lg:hidden ">
              or
            </p>
            <hr className="border-[#F4F5F8] border border-solid w-1/3 md:hidden lg:hidden" />
          </div>

          <button
            disabled={isButtonDisabled}
            className="h-16 w-full md:hidden lg:hidden  mt-5 flex justify-center items-center gap-2 bg-white rounded-lg border-solid border-[#EEF0F4] border-[2px] font-semibold"
            onClick={handleClickContinueWithGoogle}
          >
            <img src={googleImage} alt="google" className="flex w-5 h-5" />
            <span className="">Continue with Google</span>
          </button>

          <div className="flex justify-center gap-1 pt-2">
            <p className="flex text-gray-400">Don't have an account?</p>
            <Link to="/signup" className="text-[#1C79BF] cursor-pointer">
              Sign-up
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
