import React, {useEffect} from 'react'
import Sidebar1 from './components/Sidebar1';
import Navbar1 from './components/Navbar1';

const HelpCenter = () => {

  const tabName = "/ Help Center";

  useEffect(() => {
    document.title = "Help Center - ICTSuhashi";
  }, []);

  return (
    <div className="flex">
      <Sidebar1 />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} />

        <div className="flex-grow">
          <p className="mt-2 ml-2 text-lg font-semibold">Help center</p>

          <div className="flex mt-2 flex-row gap-[100px]md "></div>

          <div className="flex flex-col py-4 ml-4 ">
            <div className="w-full h-[320px] justify-between bg-[#FFF]  rounded-[20px]">
              <div className="flex justify-between mt-4 ml-4 mr-4">
                <p className=" text-[#031F31] font-bold">Help center</p>
              </div>

              <div className="flex flex-row ml-[10px] pt-2 gap-9">
                <div className="border border-[#F0F2F4]  bg-[#fff]  h-[230px] w-[850px] rounded-lg">
                  <div className="flex flex-col gap-3 mt-3 ml-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpCenter