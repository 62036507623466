import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { Link, useParams } from "react-router-dom";

import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import Footer from "../Home/Components/Footer";
import { truncateString } from "./../../helpers/stringHelpers";

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [showNoVideosMessage, setShowNoVideosMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const { eduLevelId, courseId, chapterId } = useParams();
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };

  const tabName = "/ Grades / Courses / Lessons / Videos";

  useEffect(() => {
    document.title = "Videos - ICTSuhashi";
  }, []);

  useEffect(() => {
    setShowNoVideosMessage(false);
    setLoading(true);

    const fetchVideos = async () => {
      try {
        const videoSnapshot = await getDocs(
          collection(
            db,
            `edu_level/${eduLevelId}/courses/${courseId}/chapters/${chapterId}/videos`
          )
        );
        const videos = videoSnapshot.docs.map((video) => ({
          video_id: video.id,
          video_data: video.data(),
        }));

        // // Update the state with all chapters after all iterations are done
        setVideos(videos);
        setLoading(false);
        setShowNoVideosMessage(videos.length === 0);
      } catch (error) {
        console.error("Error fetching videos: ", error);
        setShowNoVideosMessage(true);
      }
    };

    fetchVideos();
  }, []);

  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {videos.length === 0 && !loading ? (
          <div className="flex flex-col items-center mt-10">
            <p>No data available. Please check back later.</p>
          </div>
        ) : (
          ""
        )}
        <div className="min-h-screen px-4 mx-auto my-2 ">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
            {videos.length > 0 ? (
              videos
                .sort((a, b) => {
                  //Assuming 'dateTime' is a property of each edulevelDoc
                  const dateTimeA = new Date(
                    `${a.video_data.date} ${a.video_data.time}`
                  );
                  const dateTimeB = new Date(
                    `${b.video_data.date} ${b.video_data.time}`
                  );
                  // return dateTimeB - dateTimeA;
                  return dateTimeA - dateTimeB;
                })
                .map((videoDoc) => (
                  <div
                    className="flex flex-col items-center p-4 px-2 py-4 transition duration-300 ease-in-out bg-white border rounded-lg shadow-sm hover:shadow-md"
                    key={videoDoc.video_id}
                  >
                    {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="text-[#000000] h-12 w-12 mx-3 text-center justify-center">
                    <circle cx={12} cy={12} r={10} />
                    <polygon points="10 8 16 12 10 16 10 8" />
                  </svg> */}

                    {/* <iframe
                  style={{width: '100%' }}
                  src={videoDoc.video_data.url}
                  title="Embedded Video"
                ></iframe> */}
                    <div
                      className="overflow-hidden bg-black rounded-md"
                      style={{
                        position: "relative",
                        width: "100%",
                        paddingTop: "56.25%",
                      }}
                    >
                      <iframe
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        src={videoDoc.video_data.url}
                        title="Embedded Video"
                        allowFullScreen
                      ></iframe>
                    </div>

                    <div className="flex-1 pt-1 font-medium text-center text-black">
                      {truncateString(videoDoc.video_data.title, 20)}
                    </div>
                    {/* <div className="flex-1 text-center text-black">
                    
                  </div> */}
                    <div className="flex-1 w-full text-center">
                      <Link
                        to={`/video/${eduLevelId}/${courseId}/${chapterId}/${videoDoc.video_id}`}
                      >
                        <button
                          className={`rounded-lg w-full px-4 py-2 bg-green-300 mt-2`}
                        >
                          {`Watch Video`}
                        </button>
                      </Link>
                    </div>
                  </div>
                ))
            ) : showNoVideosMessage ? (
              // <div className="flex items-center justify-center col-span-4 text-lg text-center text-gray-500">
              //   No Videos Available
              // </div>
              <div className="flex items-center justify-center col-span-4 text-lg text-center text-gray-500"></div>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Videos;
